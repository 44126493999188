import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Layover: React.FC<Props> = ({ children }) => (
  <div
    className="w-full h-full flex"
    style={{
      background: "url(/bg.png) no-repeat center center fixed",
      backgroundSize: "cover",
    }}
  >
    <div
      className="m-auto text-white p-4"
      style={{
        border: "1px solid #BA8E47",
        borderRadius: "25px",
        background: "rgb(0 0 0)",
        width: "750px",
      }}
    >
      {children}
    </div>
  </div>
);

export default Layover;
