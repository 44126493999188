import React from "react";
import { useNavigate } from "react-router-dom";
import Layover from "src/components/Layover";
import "../index.css";

const Rules: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Layover>
      <button
        style={{
          border: "1px solid #CAA75F",
          background: "linear-gradient(45deg, #8a662d, #d0ad65)",
          padding: ".25rem .5rem",
          borderRadius: ".2rem",
          fontSize: ".875rem",
          transition:
            "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        }}
        className="text-white hover:!border-black !cursor-pointer"
        onClick={() => navigate(-1)}
      >
        Geri Git
      </button>

      <section className="mb-4">
        <h1 className="underline font-bold text-2xl text-center mb-3">
          ÖDÜL HAVUZU
        </h1>
        <ul className="pl-4 list-disc flex flex-col gap-3 text-sm">
          <li>
            <span>
              ExtraToto, haftalık 500.000 TL ödül havuzlu spor tahmin
              etkinliğidir. Her hafta belirlenen 10 karşılaşmanın sonucunu doğru
              tahmin et, ödülünü al!
            </span>
            <ul className="list-none pl-8 mt-1">
              <li>- 10 Doğru Tahmin 250.000 TL</li>
              <li>- 9 Doğru Tahmin 150.000 TL</li>
              <li>- 8 Doğru Tahmin 100.000 TL</li>
              <li>- 7 Doğru Tahmin 250 TL FreeBet</li>
            </ul>
          </li>
          <li>
            10, 9 ve 8 doğru tahminde bulunan üyelerimiz arasında belirtilen
            ödüller paylaştırılacaktır. 7 doğru tahminde bulunan üyelerimizin
            tamamı ise 250 TL FreeBet ödülünden yararlanabilecektir.
          </li>
          <li>
            Ödüller çevrim şartsız bakiye olarak eklenmekte olup ana para çevrim
            şartına tabidir. İlgili ödüller ile çekim yapabileceğiniz minimum
            tutar 200 TL, maksimum tutar ise almış olduğunuz ödülün 5 katıdır.
          </li>
          <li>
            Ödüller ilgili haftanın son maçı bittikten hemen sonraki 24 saat
            içinde üye hesaplarına otomatik olarak eklenecektir. Üyelerimizin
            herhangi bir talepte bulunmaları gerekmemektedir.
          </li>
          <li>
            Hesabınızda bakiye veya açık kupon olması durumunda sistem
            tarafından ekleme yapılamamaktadır. Bu kural nedeniyle hesabına
            ödülü eklenmeyen üyeler, ilgili haftanın son maçı bittikten sonraki
            24 saat içerisinde, bakiyeleri ve açık bahisleri bulunmadığı
            durumda, Canlı Destek hattımız üzerinden taleplerini iletebilirler.
          </li>
          <li>
            250 TL FreeBet ödülünden yararlanan üyelerimiz, minimum 500 TL,
            maksimum 1.000 TL çekim yapabilmektedir.
          </li>
        </ul>
      </section>

      <hr className="opacity-25" />

      <section className="mt-4">
        <h1 className="underline font-bold text-2xl text-center mb-3">
          KURALLAR
        </h1>
        <ul className="list-disc pl-4 flex flex-col gap-3 text-sm">
          <li>
            Karşılaşmalar her Salı günü yenilenir ve yeni ödül haftası başlar.
          </li>
          <li>
            ExtraToto'ya katılabilmeniz için yeni ödül haftasının başladığı Salı
            günü ile ödül haftasında yer alan ilk karşılaşmanın başlama saati
            aralığında tek seferde minimum 100 TL yatırım işlemi gerçekleştirmiş
            olmanız gerekmektedir.
          </li>
          <li>
            Tahminlerinizi ilk karşılaşmanın başlama saatine kadar iletmeniz
            gerekmektedir.
          </li>
          <li>Her üyemizin bir adet tahmin hakkı bulunmaktadır.</li>
          <li>
            Tahminde bulunurken kullanıcı adınızı hatalı girmeniz durumunda
            tahminleriniz geçersiz sayılmaktadır.
          </li>
          <li>
            Bir üyenin birden fazla tahminde bulunduğu veya farklı hesaplarla
            etkinliğe katıldığı tespit edilirse tüm kazançları geçersiz
            sayılacaktır.
          </li>
          <li>FreeBet, cashout ve iadeli bahisler için geçerli değildir.</li>
          <li>
            Etkinlik sonuçlandırması 90 dakikalık normal süre ve hakemin ek
            olarak vereceği 90+ uzatma süreleri sonundaki skora göre
            yapılmaktadır.
          </li>
        </ul>
      </section>
    </Layover>
  );
};

export default Rules;
